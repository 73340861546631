import React from 'react';

export const InspirationSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="17"
    fill="none"
    viewBox="0 0 20 17"
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M10 2.8L14.505.549a1.638 1.638 0 012.369 1.464v.863h.624a1.873 1.873 0 011.875 1.875v10a1.873 1.873 0 01-1.875 1.875H2.5A1.873 1.873 0 01.625 14.75v-10A1.873 1.873 0 012.5 2.875h.624v-.863A1.637 1.637 0 015.494.548L9.999 2.8zm6.875 1.325v8.124c0 .62-.35 1.187-.905 1.464l-3.322 1.662h4.851a.623.623 0 00.625-.625v-10a.623.623 0 00-.625-.625h-.624zm-13.75 0H2.5a.623.623 0 00-.625.625v10a.623.623 0 00.625.625h4.852l-3.323-1.662a1.638 1.638 0 01-.905-1.464V4.125zm6.25-.24v11.103l-4.787-2.393a.388.388 0 01-.214-.346V2.012a.387.387 0 01.56-.346l4.44 2.22zm1.25 0l4.44-2.22a.389.389 0 01.56.347V12.25c0 .147-.083.28-.214.346l-4.786 2.393V3.886z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default InspirationSvg;
