export const findAllHashtags = (value: string) => {
  const regexp = /#[A-Za-z0-9àâáåäãąæœçĉćčďðèéêëęěĝğĥîìíïıĵłľñńňòöôóõøřŝşśšßťþùúûŭüůýźżž&_-]+/g;
  const array = value.match(regexp);
  return (array || []).map(tag => tag.substring(1).toLowerCase());
};

export const getDiscoverLessonUrl = ({
  templateName,
  templateId,
}: {
  templateName?: string | null;
  templateId: string;
}) => {
  if (templateName) {
    return `/discover/${sanitizeString(templateName)}/t/${templateId}`;
  }
  return `/discover/Untitled/t/${templateId}`;
};

export const getGeneratorsUrl = ({
  title,
  generatorId,
}: {
  title?: string | null;
  generatorId: string;
}) => {
  if (title) return `/generators/${sanitizeString(title)}/g/${generatorId}`;
  return `/generators/Untitled/g/${generatorId}`;
};
export const getGeneratorsUrlFromStandards = ({
  title,
  generatorId,
  standardId,
  subject,
  grade,
}: {
  title?: string | null;
  generatorId: string;
  standardId: string;
  subject?: string;
  grade: string;
}) => {
  if (title)
    return `/generators/${sanitizeString(
      title,
    )}/g/${generatorId}?standardId=${standardId}&subject=${subject}&grade=${grade}`;
  return `/generators/Untitled/g/${generatorId}?standardId=${standardId}&subject=${subject}&grade=${grade}`;
};
export const getDiscoverCreatorUrl = (creatorName: string, creatorId: string) => {
  return `/discover/${creatorName
    .replace(/\s/g, '-')
    .replace(/[^\w\s\-]/gi, '')}/c/${creatorId}`;
};

function sanitizeString(input: string): string {
  return input
    .toLowerCase() // Convert the string to lowercase
    .replace(/\s+/g, '-') // Replace spaces with dashes
    .replace(/[^a-z\-]/g, ''); // Remove all characters that are not [a-z] or dash
}
