import { useFlag } from '@unleash/proxy-client-react';
import { Divider, Dropdown, Menu, Tooltip } from 'antd';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';

import { DeleteSvg } from '../../../assets/icons';
import BookmarkSvg from '../../../assets/icons/BookmarkSvg';
import ChartSvg from '../../../assets/icons/ChartSvg';
import ChevronRight from '../../../assets/icons/ChevronRight';
import ExternalLinkSvg from '../../../assets/icons/ExternalLinkSvg';
import FolderSvg from '../../../assets/icons/FolderSvg';
import HandshakeSvg from '../../../assets/icons/HandshakeSvg';
import HomeSvg from '../../../assets/icons/HomeSvg';
import InspirationSvg from '../../../assets/icons/InspirationSvg';
import TrainingSvg from '../../../assets/icons/Training';
import UsageGraphSvg from '../../../assets/icons/UsageGraphSvg';
import theme from '../../../assets/theme';
import { useBreakPoint } from '../../../hooks/useBreakPoint';
import useMe from '../../../hooks/useMe';
import { useMetrics } from '../../../hooks/useMetrics';
import usePaywallPrompt from '../../../hooks/usePaywallPrompt';
import useRouterMatch from '../../../hooks/useRouterMatch';
import useTenant from '../../../hooks/useTenant';
import useTenants from '../../../hooks/useTenants';
import useWorkSpace from '../../../hooks/useWorkSpace';
import CountrySchoolSelectionPopup from '../../../pages/CountrySchoolSelectionPopup';
import {
  LicenseCoarseLevels,
  LicenseCountries,
  LicenseFineLevels,
  TenantLicense,
} from '../../../utils/tenantPlanUtils';
import { zIndexes } from '../../../utils/zIndexes';
import { Button } from '../../_atoms/Button';
import { IconWrapper } from '../../_atoms/IconWrapper';
import PlanIndicator from '../../_molecules/PlanIndicator';
import CreateTeam from '../CreateTeam/CreateTeam';

export const TenantsMenuItem = () => {
  const { data, isLoading, filteredTenants, switch: switchTenant } = useTenants();

  const { tenantId: activeTenantId } = useRouterMatch();
  const { t } = useTranslation('Host');

  const isDesktop = useBreakPoint(700);
  const menu = (
    <Menu key="menu">
      <TenantMenuItemContainer>
        <p
          style={{
            marginLeft: 10,
            color: '#5F6368',
            fontSize: 12,
            userSelect: 'none',
            fontWeight: 'bold',
          }}
        >
          {t('Your spaces')}
        </p>
      </TenantMenuItemContainer>
      {filteredTenants &&
        filteredTenants.user.availableTenants.map(tenant => {
          return (
            <Menu.Item
              key={tenant.tenantId}
              disabled={activeTenantId === tenant.tenantId}
            >
              <TenantMenuItemContainer
                onClick={() => {
                  if (activeTenantId !== tenant.tenantId) {
                    switchTenant(tenant.tenantId);
                  }
                }}
              >
                <ExternalLinkSvg />
                <p style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                  {tenant.tenantName}
                </p>
              </TenantMenuItemContainer>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const displayTenant = data?.user.availableTenants
    .find(t => t.tenantId === activeTenantId)
    ?.tenantName.substring(0, 30);
  return (
    <div style={{ marginLeft: 10 }}>
      <DropDownContainer style={{ border: isLoading ? 'none' : '1px solid black' }}>
        {data ? (
          data.user.availableTenants.length >= 1 ? (
            <Dropdown
              overlayStyle={{
                boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.2)',
                overflowY: 'auto',
                maxHeight: 300,
                zIndex: 2000, // to move the overlay above the appbar
                borderRadius: 10,
              }}
              overlay={menu}
              disabled={!displayTenant || data.user.availableTenants.length === 1}
              trigger={isDesktop ? ['hover'] : ['click']}
            >
              <IconLinkContainer style={{ borderRadius: 5 }}>
                {displayTenant === undefined && (
                  <Link href={'/lessons'}>
                    <a style={{ marginBottom: 0, marginRight: 5 }}>{t('My space')}</a>
                  </Link>
                )}

                {displayTenant && <PlanIndicator isMobileDrawer={false} />}
                {displayTenant && data.user.availableTenants.length > 1 && (
                  <IconWrapperDown>
                    <ChevronRight color={theme.colors.black} />
                  </IconWrapperDown>
                )}
              </IconLinkContainer>
            </Dropdown>
          ) : (
            undefined
          )
        ) : (
          undefined
        )}
      </DropDownContainer>
    </div>
  );
};

const NewLiveSideBar = () => {
  const { router, tenantId } = useRouterMatch();

  const { t } = useTranslation('Host');
  const metrics = useMetrics();
  const [showCreateWorkspacePopup, setShowCreateWorkspacePopup] = useState(false);
  const { workspace, isFetching: isFetchingWorkspace } = useWorkSpace();
  const { me } = useMe();
  const { data: tenant } = useTenant();
  const { showNewMessages } = useIntercom();
  const isStudent = me?.role === 'student';
  const handleCreateTeam = () => {
    metrics.logEvent('CreateTeam', { from: 'Sidebar' });
    setShowCreateWorkspacePopup(true);
  };
  const [showSchoolSelector, setShowSchoolSelector] = useState(false);
  const isNewCreatePageEnabled = useFlag('enableNewCreatePage');

  const { tenantPlan } = usePaywallPrompt({
    startState: { screen: 'chooseProduct' },
  }) as { tenantPlan: TenantLicense | undefined };

  const [showSchoolUsageLink, setShowSchoolUsageLink] = useState(false);
  const isSchoolUsageEnabled = useFlag('SCHOOL_USAGE');
  useEffect(() => {
    const isInUSA = tenantPlan?.country === LicenseCountries.US;
    const isOnFullEnterprisePlan =
      tenantPlan?.coarseLevel === LicenseCoarseLevels.enterprise &&
      tenantPlan?.fineLevel === LicenseFineLevels.full;

    if (!isSchoolUsageEnabled) {
      setShowSchoolUsageLink(false);
    } else if (isInUSA && !isOnFullEnterprisePlan) {
      setShowSchoolUsageLink(true);
    } else {
      setShowSchoolUsageLink(false);
    }
  }, [isSchoolUsageEnabled, tenantPlan]);
  const [
    requireSchoolSelectionBeforeUsage,
    setRequireSchoolSelectionBeforeUsage,
  ] = useState(true);
  useEffect(() => {
    setRequireSchoolSelectionBeforeUsage(!!!me?.schoolId);
  }, [me]);

  return (
    <SideBarContainer>
      <div>
        <CreateTeam
          onDone={() => setShowCreateWorkspacePopup(false)}
          visible={showCreateWorkspacePopup}
        />
        <TenantsMenuItem />
        <SideBarItem $isActive={router.asPath.split('?')[0] === `/${tenantId}/home`}>
          <Link href={`/${tenantId}/home`}>
            <a style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <IconWrapper width={'18px'} height={'18px'} style={{ marginRight: '10px' }}>
                <HomeSvg />
              </IconWrapper>{' '}
              <span>{t('Home')}</span>
            </a>
          </Link>
        </SideBarItem>
        <SideBarItem $isActive={router.asPath === `/${tenantId}/lessons`}>
          <Link href={`/${tenantId}/lessons`}>
            <a style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <IconWrapper width={'18px'} height={'18px'} style={{ marginRight: '10px' }}>
                <FolderSvg />
              </IconWrapper>{' '}
              <span>{t('My Lessons')}</span>
            </a>
          </Link>
        </SideBarItem>

        <SideBarItem $isActive={router.asPath === `/${tenantId}/reports`}>
          <Link href={`/${tenantId}/reports`}>
            <a style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <IconWrapper width={'18px'} height={'18px'} style={{ marginRight: '10px' }}>
                <ChartSvg />
              </IconWrapper>{' '}
              <span>{t('My Reports')}</span>
            </a>
          </Link>
        </SideBarItem>
        <Divider style={{ marginBottom: 0, marginTop: 0 }} />

        <SideBarItem $isActive={router.asPath === `/${tenantId}/collections`}>
          <Link href={`/${tenantId}/collections`}>
            <a style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <IconWrapper width={'18px'} height={'18px'} style={{ marginRight: '10px' }}>
                <BookmarkSvg />
              </IconWrapper>
              <span>Collections</span>
            </a>
          </Link>
        </SideBarItem>

        {isNewCreatePageEnabled && (
          <SideBarItem
            $isActive={router.asPath.split('?')[0] === `/${tenantId}/inspiration`}
          >
            <Link href={`/${tenantId}/inspiration`}>
              <a style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <IconWrapper
                  width={'18px'}
                  height={'18px'}
                  style={{ marginRight: '10px' }}
                >
                  <InspirationSvg />
                </IconWrapper>{' '}
                <span>{t('Inspiration')}</span>
              </a>
            </Link>
          </SideBarItem>
        )}
        <Divider style={{ marginBottom: 0, marginTop: 0 }} />

        {showSchoolUsageLink &&
          (requireSchoolSelectionBeforeUsage ? (
            <SideBarItem
              $isActive={router.asPath === `/${tenantId}/usage`}
              onClick={() =>
                metrics.logEvent('SchoolUsage.clicked', { from: 'Homepage Sidebar' })
              }
            >
              <Link href={'/'}>
                <a
                  style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                  onClick={() => setShowSchoolSelector(true)}
                >
                  <IconWrapper
                    width={'18px'}
                    height={'18px'}
                    style={{ marginRight: '10px' }}
                  >
                    <UsageGraphSvg />
                  </IconWrapper>{' '}
                  <span>{t('Your school usage')}</span>
                </a>
              </Link>
            </SideBarItem>
          ) : (
            <SideBarItem
              $isActive={router.asPath === `/${tenantId}/usage`}
              onClick={() =>
                metrics.logEvent('SchoolUsage.clicked', { from: 'Homepage Sidebar' })
              }
            >
              <Link href={`/${tenantId}/usage`}>
                <a style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <IconWrapper
                    width={'18px'}
                    height={'18px'}
                    style={{ marginRight: '10px' }}
                  >
                    <UsageGraphSvg />
                  </IconWrapper>{' '}
                  <span>{t('Your school usage')}</span>
                </a>
              </Link>
            </SideBarItem>
          ))}
        {me?.role === 'superAdmin' && tenant?.districtId && (
          <>
            <SideBarItem $isActive={router.asPath === `/${tenantId}/districtUsage`}>
              <Link href={`/${tenantId}/districtUsage`}>
                <a style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <IconWrapper
                    width={'18px'}
                    height={'18px'}
                    style={{ marginRight: '10px' }}
                  >
                    <UsageGraphSvg />
                  </IconWrapper>{' '}
                  <span>{t('Your District Usage')}</span>
                </a>
              </Link>
            </SideBarItem>
            <Divider style={{ marginBottom: 0, marginTop: 0 }} />
          </>
        )}
        {!isStudent && workspace && (
          <>
            <Tooltip
              placement="rightTop"
              title={t('All workspace members can access these lessons')}
            >
              <SideBarSubHeader>{t('SHARED WORKSPACE')}</SideBarSubHeader>
            </Tooltip>
            <SideBarItem
              $isActive={router.asPath === `/${tenantId}/workspace/${workspace.id}`}
            >
              <Link href={`/${tenantId}/workspace/${workspace.id}`}>
                <a
                  style={{
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {workspace.title}
                </a>
              </Link>
            </SideBarItem>
          </>
        )}
        {!workspace &&
          !isFetchingWorkspace &&
          !isStudent &&
          tenantPlan?.isEnterprise() &&
          !tenantPlan?.isTrial() && (
            <CreateTeamItem style={{ marginTop: 20 }} onClick={handleCreateTeam}>
              <NewTeamButton type="ghost">{t('+ Create a team')}</NewTeamButton>
            </CreateTeamItem>
          )}
        {showSchoolSelector && <CountrySchoolSelectionPopup />}
      </div>
      <div style={{ flex: 1 }}>{/* Fill remaining height */}</div>
      <Divider style={{ marginBottom: 0, marginTop: 0 }} />

      <SideBarItem style={{ marginTop: '10px' }}>
        <Link href={`/c/courses`}>
          <a
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <IconWrapper width={'18px'} height={'18px'} style={{ marginRight: '10px' }}>
              <TrainingSvg />
            </IconWrapper>
            <span style={{ fontSize: 16 }}>Training</span>
          </a>
        </Link>
      </SideBarItem>

      <SideBarItem
        $isActive={router.asPath === `/${tenantId}/trash`}
        style={{ marginTop: '0px' }}
      >
        <Link href={`/${tenantId}/trash`}>
          <a
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <IconWrapper width={'18px'} height={'18px'} style={{ marginRight: '10px' }}>
              <DeleteSvg />
            </IconWrapper>{' '}
            <span>{t('Trash')}</span>
          </a>
        </Link>
      </SideBarItem>
      <Divider style={{ marginBottom: 0, marginTop: 0 }} />
      <Tooltip placement="rightTop" title={t('Suggest features or share feedback')}>
        <SideBarItem
          style={{ marginTop: '10px', marginBottom: '10px' }}
          onClick={() => showNewMessages(`Hi, I've got some feedback for you.`)}
        >
          <IconWrapper width={'22px'} height={'22px'} style={{ marginRight: '10px' }}>
            <HandshakeSvg />
          </IconWrapper>{' '}
          <span style={{ fontSize: 16 }}>{t('Help us improve')}</span>
        </SideBarItem>
      </Tooltip>
    </SideBarContainer>
  );
};
const NewTeamButton = styled(Button)`
  border: none;
  padding: 5px;
  box-shadow: none;
`;

const SideBarContainer = styled.div`
  width: 240px;
  height: calc(100% - 60px);
  padding-top: 10px;
  padding-right: 10px;
  background-color: ${theme.colors.paleBeige};
  position: fixed;
  top: 60px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.1);
  z-index: ${zIndexes('SIDEBAR')};
`;

const SideBarItem = styled.div<{ $isActive?: boolean | null }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 2px 2px 2px 20px;
  margin: 10px 0px;
  position: static;
  width: 100%;
  left: 0px;
  top: 0px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 0px 20px 20px 0px;
  a {
    color: ${theme.colors.black};
    font-weight: bold;
    font-size: 16px;
    &:hover {
      color: #282f33cc;
    }
  }

  &:hover {
    background-color: #f1eae0;
  }
  background-color: ${({ $isActive }) => ($isActive ? '#F3E8D8' : 'transparent')};
`;

const CreateTeamItem = styled(SideBarItem)`
  &:hover {
    background-color: none !important;
  }
  background-color: transparent !important;
`;

const SideBarSubHeader = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: #666;
  padding-left: 15px;
  margin-top: 20px;
  margin-bottom: 0;
  letter-spacing: 0.08rem;
  user-select: none;
`;

const IconLinkContainer = styled.div<{ $isDisabled?: boolean }>`
  position: relative;
  color: ${theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  cursor: pointer;
  ${({ $isDisabled }) =>
    $isDisabled &&
    `
  opacity: 0.7;
  cursor: progress;
  `};
  & p,
  a {
    color: ${theme.colors.black};
    margin-top: 2px;
    font-weight: bold;
    font-size: 18px;
  }
  svg {
    margin-right: 5px;
  }
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.black};
  max-width: 100%;
  p {
    margin-left: 10px;
    margin-bottom: 0;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 0px);
  }
  span {
    margin-left: 10px;
    margin-bottom: 0;
  }
  svg {
    height: 20px;
    width: 20px;
  }
`;

const TenantMenuItemContainer = styled(MenuItemContainer)`
  justify-content: flex-start;
  span {
    margin-left: 0px;
  }
  p {
    margin-left: 5px;
  }
  svg {
    margin-left: 0px;
    height: 16px;
    width: 16px;
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  padding: 10px;
  border: 1px solid ${theme.colors.black};
  border-radius: 5px;
  margin: 10px 0px 20px 0px;
  min-height: 70px;
`;

const IconWrapperDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform-origin: center;
    transform: rotate(90deg);
    height: 16px;
    width: 16px;
  }
`;

export default NewLiveSideBar;
