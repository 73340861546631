'use client';

import { useFlag } from '@unleash/proxy-client-react';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import useMe from '../../hooks/useMe';
import useTenant from '../../hooks/useTenant';
import { TenantUser } from '../../services/TenantUserService/TenantUserService';
import { LicenseFineLevels, TenantLicense } from '../../utils/tenantPlanUtils';

const isTargetUserProfile = (
  me: TenantUser | undefined,
  tenantPlan: TenantLicense | undefined,
  router: NextRouter,
) => {
  const isElaTrialTeacherInTexas =
    me?.accountType === 'teacher' &&
    me?.subjects?.includes('English Language Arts') &&
    me?.schoolState === 'TX' &&
    tenantPlan?.fineLevel === LicenseFineLevels.trial;

  const { utm_source: utmSource, utm_content: utmContent } = router.query;
  const isFacebookAdsIncomingUser =
    utmSource === 'facebook' && utmContent === 'quick_write';

  return isElaTrialTeacherInTexas || isFacebookAdsIncomingUser;
};

const HotjarSnippet = () => {
  const { me } = useMe();
  const { tenantPlan } = useTenant() as { tenantPlan: TenantLicense | undefined };
  const router = useRouter();
  const isHotjarEnabled = useFlag('HOTJAR_MONITORING');

  const [offlineSessionId] = useState<string>(String(v4()));
  const [utmTracking, setUtmTracking] = useState<{
    source: string;
    content: string;
  }>({
    source: '',
    content: '',
  });

  useEffect(() => {
    function formattedStringOrNull(value: string | null | undefined) {
      if (!!value) {
        const escapedValue = String(value).replace(/"/g, '\\"');
        return `"${escapedValue}"`;
      } else {
        return null;
      }
    }

    function getFormattedScriptContent() {
      const scriptContent = `
        (function(h,o,t,j,a,r){
          try {
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:5062971,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            r.onload = function() {
              try {
                if (window.hj) {
                  window.hj('identify', ${formattedStringOrNull(
                    me?.uid || offlineSessionId,
                  )}, {
                    schoolId: ${me?.schoolId || null},
                    schoolCity: ${formattedStringOrNull(me?.schoolCity)},
                    schoolState: ${formattedStringOrNull(me?.schoolState)},
                    schoolDistrictId: ${me?.schoolDistrictId || null},
                    subjects: ${formattedStringOrNull(me?.subjects)},
                    coarseLicense: ${formattedStringOrNull(tenantPlan?.coarseLevel)},
                    fineLicense: ${formattedStringOrNull(tenantPlan?.fineLevel)},
                    createdAt: ${me?.createdAt || null},
                    utmSource: ${formattedStringOrNull(utmTracking.source)},
                    utmContent: ${formattedStringOrNull(utmTracking.content)},
                  });
                  console.log('Hotjar identify event set');
                }
              } catch (e) {
                console.error('Error during Hotjar identify');
              }
            };
            r.onerror = function(e) {
              console.error('Error running Hotjar script');
            };
            a.appendChild(r);
          } catch (e) {
            console.error('Error loading Hotjar script');
          }
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
      return scriptContent;
    }

    try {
      const currentUtmSource = router?.query?.utm_source;
      const currentUtmContent = router?.query?.utm_content;
      setUtmTracking({
        source: !!currentUtmSource ? String(currentUtmSource) : utmTracking.source,
        content: !!currentUtmContent ? String(currentUtmContent) : utmTracking.content,
      });

      const shouldTrackUser =
        isHotjarEnabled && isTargetUserProfile(me, tenantPlan, router);

      let trackingScript = document.getElementById('hotjar-identify');
      if (!trackingScript && shouldTrackUser) {
        trackingScript = document.createElement('script');
        trackingScript.id = 'hotjar-identify';
        trackingScript.textContent = getFormattedScriptContent();
        document.head.appendChild(trackingScript);
      } else if (trackingScript && !shouldTrackUser) {
        trackingScript.remove();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [
    me,
    tenantPlan,
    isHotjarEnabled,
    router,
    offlineSessionId,
    utmTracking.source,
    utmTracking.content,
  ]);

  return <div></div>;
};

export default HotjarSnippet;
