export const BlankLessonSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4956 7.88794H2.50156C2.28065 7.88794 2.10156 8.06703 2.10156 8.28794V15.8879C2.10156 16.1089 2.28065 16.2879 2.50156 16.2879H17.4956C17.7165 16.2879 17.8956 16.1089 17.8956 15.8879V8.28794C17.8956 8.06703 17.7165 7.88794 17.4956 7.88794Z"
      fill="currentcolor"
    />
    <path
      d="M3.99961 6.65181L15.9996 6.72181C16.1057 6.72181 16.2074 6.67966 16.2825 6.60465C16.3575 6.52963 16.3996 6.42789 16.3996 6.32181C16.3996 6.21572 16.3575 6.11398 16.2825 6.03896C16.2074 5.96395 16.1057 5.92181 15.9996 5.92181L3.99961 5.85181C3.89352 5.85181 3.79178 5.89395 3.71677 5.96896C3.64175 6.04398 3.59961 6.14572 3.59961 6.25181C3.59961 6.35789 3.64175 6.45963 3.71677 6.53465C3.79178 6.60966 3.89352 6.65181 3.99961 6.65181Z"
      fill="currentcolor"
    />
    <path
      d="M5.89512 4.51191L14.0951 4.58191C14.2012 4.58191 14.3029 4.53977 14.378 4.46476C14.453 4.38974 14.4951 4.288 14.4951 4.18191C14.4951 4.07583 14.453 3.97409 14.378 3.89907C14.3029 3.82406 14.2012 3.78191 14.0951 3.78191L5.89512 3.71191C5.78903 3.71191 5.68729 3.75406 5.61227 3.82907C5.53726 3.90409 5.49512 4.00583 5.49512 4.11191C5.49512 4.218 5.53726 4.31974 5.61227 4.39476C5.68729 4.46977 5.78903 4.51191 5.89512 4.51191Z"
      fill="currentcolor"
    />
  </svg>
);
